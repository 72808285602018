import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";
import ServiceStatesMapSmall from "../../components/service-states/service-states-map-sm";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import Navigation from "../../components/navigation/navigation";
import Button from "../../components/custom-widgets/button";
import ContactInfo from "../../components/contact-info/contact-info";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

const AboutUs = () => {
  const title = "Our Company - Our Promise, Difference & Values";
  const description =
    "Welcome to WaFd Bank we provide checking, savings and mortgage services in the communities we serve. Visit a branch to take care of your banking needs";

  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(relativePath: { eq: "hero/about-us/hero-about-us-02-XXL.jpg" }) {
        ...heroImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/about-us/hero-about-us-02-XL.jpg" }) {
        ...heroImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/about-us/hero-about-us-02-LG.jpg" }) {
        ...heroImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/about-us/hero-about-us-02-MD.jpg" }) {
        ...heroImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/about-us/hero-about-us-02-SM.jpg" }) {
        ...heroImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/about-us/hero-about-us-02-XS.jpg" }) {
        ...heroImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/about-us/hero-about-us-02-XXS.jpg" }) {
        ...heroImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title: "Our Company - Our Promise, Difference & Values",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-about-us-02-250.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "about-us-hero",
    ...getHeroImgVariables(imgData),
    altText: "Group of Walt banks",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "WaFd Bank Has Been Awarded Best Bank!",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            id: "about-us-hero-cta",
            text: "Best Bank",
            url: "/about-us/best-bank",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      title: "About Us",
      active: true
    }
  ];

  const navigationData = {
    sectionClass: "bg-light py-3 mt-3 mb-4",
    linksData: [
      {
        id: "nav-about-us",
        href: "/about-us",
        title: "About Us"
      },
      {
        id: "nav-investor-relations",
        href: "/about-us/investor-relations",
        title: "Investor Relations"
      },
      {
        id: "nav-careers",
        href: "/about-us/banking-careers",
        title: "Careers"
      },
      {
        id: "nav-community-relations",
        href: "/about-us/community-giving",
        title: "Community Relations"
      },
      {
        id: "nav-reviews",
        href: "/about-us/reviews",
        title: "Reviews"
      },
      {
        id: "nav-contact-us",
        href: "/about-us/contact-us",
        title: "Contact Us"
      }
    ]
  };

  const videoData100Year = {
    title: "WaFd Bank 100 Year Video",
    vimeoId: "196913674",
    minHeight: "300px",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const videoDataNeighbors = {
    title: "Neighbors helping neighbors video",
    vimeoId: "423387535",
    minHeight: "300px",
    class: "m-auto iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <Navigation {...navigationData} />
      <section id="intro-section" className="container pt-0">
        <div className="row">
          <div className="col-md-6">
            <VimeoVideoFrame {...videoDataNeighbors} />
          </div>
          <div className="col-md-6">
            <h1>About Us</h1>
            <p>
              WaFd Bank is a regional bank, but we've tried to act more like a reliable next door neighbor. Ever since
              we got our start back in 1917, we've been helping to build healthy, thriving communities. And, as active
              members of our communities, we're truly invested in clients like you—delivering simple, straightforward
              banking solutions to help you make the most of your money.
            </p>
            <p>
              At WaFd Bank, we love what we do and making a difference in the communities we serve. That's why we
              created the Washington Federal Foundation—to facilitate giving to local nonprofits that serve the needs of
              low- and moderate-income individuals. See our{" "}
              <Link id="about-us-community-giving-link" to="/about-us/community-giving">
                Community Relations
              </Link>{" "}
              and{" "}
              <Link id="about-us-csr-link" to="/about-us/investor-relations/csr">
                Corporate and Social Responsibility
              </Link>{" "}
              pages for more information.
            </p>
          </div>
        </div>
      </section>
      <section id="forbes-best-bank-section" className="bg-success">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 col-lg-auto">
              <StaticImage
                src="../../images/forbes-badge.jpg"
                alt="America's Best Midsize Employers"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-7 col-lg">
              <h2 className="text-white">Forbes recognized WaFd Bank as one of America's Best Employers</h2>
              <Link
                id="about-us-best-employer-link"
                className="text-white text-decoration-none"
                to="/about-us/best-employer"
              >
                Finding Meaningful Work in a Tangled Workplace{" "}
                <FontAwesomeIcon className="ml-2" icon={["fal", "arrow-right"]} />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="our-story-section" className="container pb-0">
        <div className="row">
          <div className="col-md-6">
            <h2>Our story is simple. Just like our approach to banking.</h2>
            <p>
              It all started back in 1917. A group of local businessmen established Ballard Savings and Loan Association
              in a fishing and lumber community just north of Seattle, Washington.
            </p>
            <p>
              More than a century later, we continue to provide one-on-one, friendly and professional service. No phone
              trees. No jumping through hoops. Just the personal attention and customized, expert advice you deserve.
              Call our Client Care Center seven days a week at{" "}
              <a id="about-us-c3-tel-link" href="tel:800-324-9375">
                800-324-9375
              </a>
              .
            </p>
          </div>
          <div className="col-md-6">
            <VimeoVideoFrame {...videoData100Year} />
          </div>
        </div>
      </section>
      <section id="featured-reviews-section" className="bg-light">
        <div className="container">
          <h2 className="text-green-60">Featured Reviews</h2>
          <div className="row">
            <div className="col-md-6">
              <p>
                "I began trying to refinance my house after rates fell this summer. I spoke with three other banks who
                said they could not help or were not interested because of the uniqueness of my property (on 5 acres,
                1st and 2nd loans on property) I found WaFd Bank. They were more than willing to work with me. I like
                the fact that WaFd does not sell their loans and has more flexibility in the process. It was not a “one
                size fits all” approach. I highly recommend anyone looking to refinance or switch banks to contact WaFd
                Bank!"
              </p>
              <p className="mb-0">
                <strong>Joe W.</strong>
              </p>
              <p>Property owner, Denton Texas</p>
            </div>
            <div className="col-md-6">
              <p>
                “We are stoked that you were involved helping us. Your teams have been working like crazy and we have
                had numerous times in the last year and a half when one WaFd Bank employee was overlapping another WaFd
                employee unknowingly. That probably wouldn't happen at another bank because they don't work as hard as
                WaFd. Heck, the CEO called me after midnight in April 2020 to get our Ops loan approved initially. That
                was simply amazing. That would not happen at any bank except WaFd Bank. You and your teammates
                absolutely rock. THANK YOU.”
              </p>
              <p className="mb-0">
                <strong>T.F.</strong>
              </p>
              <p>Business Owner/Developer</p>
            </div>
          </div>
          <Button type="link" url="/about-us/reviews" text="More reviews" id="about-us-reviews-link" showIcon={false} />
        </div>
      </section>
      <section id="locations-section" className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-green-60">Over 200 offices to serve you.</h2>
            <p>
              From{" "}
              <Link id="about-us-checking-account-link" to="/personal-banking/checking-account">
                checking
              </Link>
              ,{" "}
              <Link id="about-us-savings-account-link" to="/personal-banking/savings-account">
                savings
              </Link>{" "}
              and{" "}
              <Link id="about-us-retirement-account-link" to="/personal-banking/retirement-ira-accounts">
                retirement accounts
              </Link>{" "}
              to {""}
              <Link id="about-us-home-loan-link" to="/personal-banking/home-loans">
                home loan
              </Link>{" "}
              and{" "}
              <Link id="about-us-commercial-loan-link" to="/commercial-banking/commercial-real-estate-loans">
                corporate real estate financing
              </Link>
              , WaFd Bank is ready to help bring clarity to banking. We operate in nine western states, including{" "}
              <Link id="about-us-arizona-link" to="/locations/arizona">
                Arizona
              </Link>
              ,{" "}
              <Link id="about-us-california-link" to="/locations/california">
                California
              </Link>
              ,{" "}
              <Link id="about-us-idaho-link" to="/locations/idaho">
                Idaho
              </Link>
              ,{" "}
              <Link id="about-us-nevada-link" to="/locations/nevada">
                Nevada
              </Link>
              ,{" "}
              <Link id="about-us-new-mexico-link" to="/locations/new-mexico">
                New Mexico
              </Link>
              ,{" "}
              <Link id="about-us-oregon-link" to="/locations/oregon">
                Oregon
              </Link>
              ,{" "}
              <Link id="about-us-texas-link" to="/locations/texas">
                Texas
              </Link>
              ,{" "}
              <Link id="about-us-utah-link" to="/locations/utah">
                Utah
              </Link>
              , and{" "}
              <Link id="about-us-washington-link" to="/locations/washington">
                Washington
              </Link>
              .
            </p>
            <p>
              Our common-sense, disciplined approach has stood the test of time and made us one of the strongest
              financial institutions in America. Find a <Link to="/locations">location</Link> near you.
            </p>
            <h5>At a Glance</h5>
            <ul id="at-a-glance-list">
              <li id="at-a-glance-list-item-1">Parent company: WaFd, Inc.</li>
              <li id="at-a-glance-list-item-2">Nasdaq Trading Symbol: WAFD</li>
              <li id="at-a-glance-list-item-3">Corporate Headquarters: 425 Pike Street, Seattle, WA 98101</li>
              <li id="at-a-glance-list-item-4">President &amp; CEO: Brent Beardall</li>
            </ul>
          </div>
          <div className="col-md-6">
            <ServiceStatesMapSmall />
          </div>
        </div>
      </section>
      <ContactInfo />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default AboutUs;
